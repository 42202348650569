// // BackdropLoader.js
// import React from 'react';

// const Loader = ({ isLoading }) => {
//   if (!isLoading) return null; // Do not render anything if not loading

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//       <div className="loader">
//         <div className="w-16 h-16 border-4 border-[#ffb907] border-t-transparent rounded-full animate-spin"></div>
//       </div>
//     </div>
//   );
// };

// export default Loader;
import React from 'react';

const Loader = ({ isLoading }) => {
  if (!isLoading) return null; // Do not render anything if not loading

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="loader">
        <div
          className="w-16 h-16 rounded-full border-4 animate-spin"
          style={{
            borderColor: 'transparent', // Make base border transparent
            borderTopColor: 'transparent', // Top part stays transparent
            borderRightColor: 'transparent',
            borderImageSource: 'linear-gradient(to right, #38bdf8, #34d399)', // Apply gradient for other parts
            borderImageSlice: 1,
            borderWidth: '4px',
            maskImage: 'radial-gradient(circle, black 99%, transparent 100%)', // Circle masking
            WebkitMaskImage: 'radial-gradient(circle, black 99%, transparent 100%)',
          }}
        ></div>
      </div>
    </div>
  );
};

export default Loader;
