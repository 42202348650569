import React from "react";

function MentorSection() {
  return (
    <section className="py-16 font-sans " style={{ backgroundImage: 'url(/images/banner-4.png)', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}>
      <div className="container mx-auto px-4 lg:px-4 max-w-[67rem]">
        <div className="bg-[#FFFFFF17] rounded-lg shadow-2xl p-6 md:p-16" data-aos="fade-up">
          <div className="flex flex-col lg:flex-row items-center lg:space-x-12">
            {/* Mentor Description */}
            <div className="text-gray-800 lg:w-2/3 space-y-6 lg:space-y-8 order-2 md:order-1">
              <h2 className="text-[26px] md:text-[34px] text-white mb-8 text-center md:text-left">
                Meet Your <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">Mentor</span>
              </h2>
              <p className="text-lg md:text-xl font-light leading-relaxed text-[#CACACA] text-center md:text-left">
                I'm a Brand Marketing Leader with over{" "}
                <span className="font-bold">13 years of experience</span>{" "}
                creating impactful digital and content strategies across various industries.
              </p>
              <p className="text-lg md:text-xl font-light leading-relaxed text-[#CACACA] text-center md:text-left">
                Having{" "}
                <span className="font-bold">
                  partnered with iconic brands like Coca-Cola, Sprite, and Sony, and led Uber's global social media initiatives
                </span>, I now head brand marketing and PR at Even, a healthcare startup revolutionizing Indian healthcare.
              </p>
              <p className="text-lg md:text-xl font-light leading-relaxed text-[#CACACA] text-center md:text-left">
                As someone who uses AI frequently to make life easier, I’m excited to share my insights and hacks with you!
              </p>
            </div>

            {/* Mentor Image & Details */}
            <div className="lg:w-1/3 text-center lg:text-left mt-8 lg:mt-0 space-y-4 order-1 md:order-2 mb-8 md:mb-0">
              <div className="flex flex-col items-center lg:items-start">
                <img
                  src="/images/hero-ben.jpg"
                  alt="hero"
                  className="w-40 h-40 md:w-48 md:h-48 rounded-full shadow-xl"
                />
                <div className="mt-4">
                  <p className="text-2xl font-semibold text-white">Nikhil Kant</p>
                  <p className="text-lg text-[#CACACA]">Brand Marketing Leader & AI Expert</p>
                </div>
              </div>
              <div className="mt-6 text-center lg:text-left">
                <button className="cta-button text-white px-8 py-3 rounded-md shadow-md text-lg font-medium transition duration-300 ease-in-out">
                  Connect with Nikhil
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MentorSection;
