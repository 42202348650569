import React from "react";
import { motion } from "framer-motion";

const stepsOldWay = [
  "Fear of job loss due to AI",
  "Lacked practical skills to stand out",
  "Pursued generic skill courses",
  "Hoped for promotion",
  "Felt drained daily with no hope",
  "Faced multiple failures, no progress",
  "Grew frustrated and blamed others",
  "Neglected well-being",
  "Experienced declining health",
];

const stepsNewWay = [
  "Understood how jobs are evolving",
  "Attended webinars, identified root causes",
  "Week 1: Created a standout resume",
  "Week 2: Applied to 200+ AI jobs",
  "Week 3: Practiced Job Interview Mastery",
  "Gained confidence and secured a job",
  "Stopped chasing every trend",
  "No longer scared of job insecurity",
  "Excelled in AI, secured career",
  "Life became joyful and fulfilling",
];

const stepVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.6 },
  },
};

const Comparison = () => {
  return (
    <section className="py-12 font-sans">
      <div className="container mx-auto px-4 max-w-[67rem]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Old Way Section */}
          <div className="bg-gradient-to-r from-red-100 to-red-200 p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <h2 className="text-3xl font-bold text-red-600 mb-6 text-center border-b-2 border-red-300 pb-2">
              Old Way
            </h2>
            <div className="relative">
              {stepsOldWay.map((step, index) => (
                <motion.div
                  key={index}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={stepVariants}
                  className="flex items-center mb-8 relative"
                >
                  {/* Step Indicator */}
                  <div className="absolute -left-[0px] flex items-center justify-center w-10 h-10 bg-red-600 text-white rounded-full font-bold z-[1]">
                    {index + 1}
                  </div>
                  {/* Step Description */}
                  <p className="text-lg text-red-800 ml-16">{step}</p>
                  {/* Progress Bar */}
                  {index < stepsOldWay.length - 1 && (
                    <motion.div
                      className="absolute left-[18px] top-[30px] w-1 h-12 bg-red-400 z-0"
                      initial={{ height: 0 }}
                      whileInView={{ height: "100%" }}
                      viewport={{ once: true }}
                    ></motion.div>
                  )}
                </motion.div>
              ))}
            </div>
          </div>

          {/* New Way Section */}
          <div className="bg-gradient-to-r from-green-100 to-green-200 p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300">
            <h2 className="text-3xl font-bold text-green-600 mb-6 text-center border-b-2 border-green-300 pb-2">
              New Way
            </h2>
            <div className="relative">
              {stepsNewWay.map((step, index) => (
                <motion.div
                  key={index}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={stepVariants}
                  className="flex items-center mb-8 relative"
                >
                  {/* Step Indicator */}
                  <div className="absolute -left-[0] flex items-center justify-center w-10 h-10 bg-green-600 text-white rounded-full font-bold z-[1]">
                    {index + 1}
                  </div>
                  {/* Step Description */}
                  <p className="text-lg text-green-800 ml-16">{step}</p>
                  {/* Progress Bar */}
                  {index < stepsNewWay.length - 1 && (
                    <motion.div
                      className="absolute left-[18px] top-[30px] w-1 h-12 bg-green-400 z-0"
                      initial={{ height: 0 }}
                      whileInView={{ height: "100%" }}
                      viewport={{ once: true }}
                    ></motion.div>
                  )}
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Comparison;
