import React from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
    <div className="items-center flex text-center justify-center bg-gray-800">
            <Link to="/">
            <img
            src="/images/NFA-Logo.png"
            alt="Logo"
            className="bg-gradient w-[220px] p-3 py-2 rounded mb-12 mt-12"
            />
            </Link>
        </div>
    <section className="py-12 font-sans">
        <div className="container mx-auto px-4 max-w-[67rem]">
        
        <div className="py-10 px-4 sm:px-8 lg:px-16 text-gray-800">
            <div className="max-w-4xl mx-auto">
            <h2 className="text-[26px] md:text-[34px] text-black mb-8 text-center md:text-left">
                <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">Cancellation & Refund Policy</span> for NextFutureai with AI
            </h2>
            <p className="mb-4">
                At NextFutureai with AI, accessible from{" "}
                <Link
                to="#"
                rel="noopener noreferrer"
                className="text-blue-600 underline"
                >
                https://nextfutureai.com/
                </Link>
                , one of our main priorities is the privacy of our visitors. This
                Privacy Policy document contains types of information that is
                collected and recorded by NextFutureai with AI and how we use it.
            </p>
            <p className="mb-4">
                If you have additional questions or require more information about
                our Privacy Policy, do not hesitate to contact us.
            </p>
            <p className="mb-4">
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the information
                that they shared and/or collect in NextFutureai with AI. This policy is
                not applicable to any information collected offline or via channels
                other than this website.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">Consent</h2>
            <p className="mb-4">
                By using our website, you hereby consent to our Privacy Policy and
                agree to its terms.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">Information we collect</h2>
            <p className="mb-4">
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to you at
                the point we ask you to provide your personal information.
            </p>
            <p className="mb-4">
                If you contact us directly, we may receive additional information
                about you such as your name, email address, phone number, the
                contents of the message and/or attachments you may send us, and any
                other information you may choose to provide.
            </p>
            <p className="mb-4">
                When you register for an Account, we may ask for your contact
                information, including items such as name, company name, address,
                email address, and telephone number.
            </p>
            <h2 className="text-2xl font-semibold mt-6 mb-4">How we use your information</h2>
            <ul className="list-disc list-inside mb-4 space-y-2">
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyze how you use our website</li>
                <li>Develop new products, services, features, and functionality</li>
                <li>Communicate with you, either directly or through one of our partners</li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
            </ul>
            <h2 className="text-2xl font-semibold mt-6 mb-4">Log Files</h2>
            <p className="mb-4">
                NextFutureai with AI follows a standard procedure of using log files.
                These files log visitors when they visit websites. All hosting
                companies do this and a part of hosting services' analytics...
            </p>
            {/* Continue adding sections as needed */}
            </div>
        </div>
        </div>
        </section>
        <Footer />
    </>
  );
};

export default PrivacyPolicy;
