import React, { useEffect } from 'react';
import HeroSection from '../components/HeroSection';
import WorkshopSlider from '../components/WorkshopSlider';
import WhatYouWillLearn from '../components/WhatYouWillLearn';
import AIToolsSection from '../components/AIToolsSection';
import MentorSection from '../components/MentorSection';
import CareerImpactSection from '../components/CareerImpactSection';
import OfferSection from '../components/OfferSection';
import WorkshopCertificate from '../components/WorkshopCertificate';
import BonusSection from '../components/BonusSection';
import FAQSection from '../components/FAQSection';
import Testimonials from '../components/Testimonials';
import CompanySection from '../components/CompanySection';
import CounterSection from '../components/CounterSection';
import BlogSection from '../components/BlogSection';
import WhatSayIdeal from '../components/WhatSayIdeal';
import Footer from '../components/Footer';
import CountdownTimer from '../components/CountdownTimer';
import { addVisitClick } from '../utils/AddClick';


const Home = () => {
    useEffect(()=>{
         addVisitClick()
    },[])
    return (
        <>      
            <HeroSection />
            <WorkshopSlider />
            <CompanySection />
            <WhatYouWillLearn />
            <AIToolsSection />
            <MentorSection />
            <CareerImpactSection />
            <OfferSection />
            <BonusSection />
            <WorkshopCertificate />
            <CounterSection />
            <WhatSayIdeal />
            <FAQSection />  
            <Testimonials />
            <Footer />
            <CountdownTimer />
        </>
    )
}

export default Home; 