import React from "react";
import { FaCheck } from "react-icons/fa6";

function CareerImpactSection() {
  const impactItems = [
    {
      title:"Enhance Your Skill Set",
      text: "Mastering new tools, technologies, or methodologies can make you more versatile, increasing your value and appeal in the job market.",
    },
    {
      title:"Increase Your Job Security",
      text: "By becoming an expert in high-demand skills, you can position yourself as irreplaceable, securing more opportunities and career stability.",
    },
    {
      title:"Accelerate Career Growth",
      text: "Acquiring in-demand skills or certifications can open doors to promotions, leadership roles, or higher-paying positions, fast-tracking your career progression.",
    },
    // {
    //   title:"",
    //   text: "Boost job prospects with AI-optimized resumes and tailored applications.",
    // },
    // {
    //   title:"",
    //   text: "Use AI-driven data analysis for informed decision-making without technical skills.",
    // },
    // {
    //   title:"",
    //   text: "Build a strong personal brand using AI-generated content to attract professional opportunities.",
    // },
    // {
    //   title:"",
    //   text: "Enhance presentations with real-time insights and visuals for impactful data communication.",
    // },
  ];

  return (
    <section className="py-16 pt-10 bg-gray-50 font-sans">
      <div className="container mx-auto px-4 lg:px-4 max-w-[67rem]">
        <div className="text-center mb-12" data-aos="fade-up">
          <h2 className="text-[26px] md:text-[34px] text-center text-black mb-8 leading-tight">
          How This Can<span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent"> Transform Your Career Path</span>
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8" data-aos="fade-up">
          {impactItems.map((item, index) => (
            <div
              key={index}
              className="bg-white rounded-2xl shadow-xl p-8 transform transition-all hover:scale-105 hover:shadow-2xl"
            >
              <FaCheck className="text-text-primary w-16 h-16 mb-6 bg-gray-200 p-4 rounded-full mx-auto shadow-md transition-all transform hover:scale-110" />
              <h1 className="text-center text-xl font-bold text-[#1f2937]">{item.title}</h1>
              <p className="text-lg font-medium text1-[#1f2937] text-gray-700 leading-relaxed text-center">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default CareerImpactSection;
