import React from "react";

const testimonials = [
  {
    name: "Abhishek Jain",
    position: "Tech Lead",
    feedback:
      "This platform is fantastic! It simplifies challenging tasks gorgeous and easy-to-navigate interface.",
    image: "/images/client1.png",
  },
  {
    name: "Anamika Gupta",
    position: "CTO, Innovatech",
    feedback:
      "Their solutions are revolutionary! This tool is essential for any tech company.",
    image: "/images/client2.png",
  },
  {
    name: "Faizan Khan",
    position: "UI/UX Designer",
    feedback:
      "The design and functionality are amazing. Its like stepping into the future of technology.",
    image: "/images/client3.png",
  },
];

const Testimonials = () => {
  return (
    <section className="testimonial-section py-16 pt-10 font-sans " style={{ backgroundImage: 'url(/images/banner-4.png)', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}>
      <div className="container mx-auto max-w-[67rem]">
        <div className="" data-aos="fade-up">
          <h2 className="text-[26px] md:text-[34px] text-white mb-8 leading-tight">
            What Our <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">Clients Say</span>
          </h2>
          <div className="testimonial-wrapper " data-aos="fade-up">
            {testimonials.map((testimonial, index) => (
              <div className="testimonial-card bg-[#FFFFFF17]" key={index}>
                <div className="card-content">
                  <img
                    src={testimonial.image}
                    alt={`${testimonial.name}`}
                    className="testimonial-image bg-white m-auto"
                  />
                  <h3 className="testimonial-name">{testimonial.name}</h3>
                  <p className="testimonial-position">{testimonial.position}</p>
                  <p className="testimonial-feedback">{`"${testimonial.feedback}"`}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
