import React from 'react';

const WhatSayIdeal = () => {
  const images = [
    '/images/twitter_review1.jpg', // Replace with actual image paths
    '/images/twitter_review2.jpg',
    '/images/twitter_review3.jpg',
    '/images/twitter_review4.jpg',
    '/images/twitter5.png',
  ];

  return (
    <section
      className="pt-10 pb-16 bg-black"
      style={{ backgroundImage: 'url(/images/banner-4.png)', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}
    >
      <div className="container mx-auto px-4 max-w-[67rem]">
        {/* Section Title */}
        <div className="text-center mb-10" data-aos="fade-up">
          <h2 class="text-[26px] md:text-[34px] text-center text-white mb-1 leading-tight">
            What Say <span class="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">Ideal</span>
          </h2>
          <p className="text-gray-400 mt-2 text-lg md:text-xl">
            Hear what successful people have to say about AI
          </p>
        </div>

        {/* Grid Layout for 2 Rows */}
        <div
          className=" gap-8 md:gap-10"
          style={{
            gridTemplateRows: 'auto auto', // Ensures both rows have automatic height
          }}
        >
          {/* First Row (3 images) */}
          <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4' data-aos="fade-up">
          <div className="relative rounded-xl  overflow-hidden transform transition-all duration-300 ease-in-out hover:scale-105 h-auto">
            <img
              src={images[0]}
              alt="Twitter 1"
              className="w-full rounded-xl h-full"
            />
          </div>
          <div className="relative rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 ease-in-out hover:scale-105">
            <img
              src={images[1]}
              alt="Twitter 2"
              className="w-full rounded-xl h-full"
            />
          </div>
          <div className="relative rounded-xl  overflow-hidden transform transition-all duration-300 ease-in-out hover:scale-105">
            <img
              src={images[2]}
              alt="Twitter 3"
              className="w-full rounded-xl h-full"
            />
          </div>
          </div>

          {/* Second Row (2 images) */}
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4' data-aos="fade-up">
          <div className="relative rounded-xl  overflow-hidden transform transition-all duration-300 ease-in-out hover:scale-105">
            <img
              src={images[3]}
              alt="Twitter 4"
              className="w-full rounded-xl "
            />
          </div>
          <div className="relative rounded-xl  overflow-hidden transform transition-all duration-300 ease-in-out hover:scale-105">
            <img
              src={images[4]}
              alt="Twitter 5"
              className="w-full rounded-xl "
            />
          </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatSayIdeal;
