import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaAward, FaLinkedin } from "react-icons/fa"; // Icons for certificate and LinkedIn
import { Link } from "react-router-dom"; // Import Link from React Router
import RegisterButton from "./RegisterButton";

const WorkshopCertificate = () => {
  const [data,setData] = useState([])
  const getData = async() => {
    try{
      const res =await axios.get(`${process.env.REACT_APP_API_PORT}get_class_data`)
      console.log(res)
      if(res.data.status===true){
        console.log(res.data)
        setData(res.data.data)
      }
    }
    catch(err){
      console.log(err)
      if(err.response.data.status===false){
        alert(err.response.data.message)
      }
    }
  }

  useEffect(()=>{
    getData()
  },[])
  return (
    <section className="bg-gray-200 py-16 pt-10 font-sans">
      <div className="max-w-6xl mx-auto px-6 lg:px-16">
        {/* Header */}
        <div className="text-center mb-12" data-aos="fade-up">
          <h2 className="text-[26px] md:text-[34px] text-center text-black mb-2 leading-tight">
          Accelerate Your Career with  <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">Certification!</span>
          </h2>
          <p className="text-lg md:text-xl text-gray-600">
          Showcase your skills and make an impact.
          </p>
        </div>

        {/* Main Section */}
        <div className="bg-white shadow-lg rounded-3xl overflow-hidden lg:flex" data-aos="fade-up">
          {/* Image Section */}
          {/* <div className="relative lg:w-1/2">
            <img
              src="/images/home_certificate.jpeg"
              alt="Certificate"
              className="w-full h-full object-cover object-left hover:scale-105 transition-transform duration-300"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-blue-900 via-transparent to-transparent opacity-60"></div>
          </div> */}
          <div className="relative lg:w-1/2 ">
  <img
    src="/images/new_certificate.jpeg"
    alt="Certificate"
    className="w-full h-full object-contain hover:scale-105 transition-transform duration-300"
  />
  <div className="absolute inset-0 bg-gradient-to-t from-blue-900 via-transparent to-transparent1 to-blue-50 opacity-60"></div>
</div>

          {/* Content Section */}
          <div className="lg:w-1/2 p-8 lg:p-16 space-y-8 flex flex-col justify-center text-center md:text-left">
            {/* Validate Learning */}
            <div>
              <h3 className="text-2xl font-bold text-black">
              Prove What You have Learned
              </h3>
              <p className="text-gray-700 mt-2">
              Showcase Your Dedication and Expertise with a Mentor-Signed Certificate
              </p>
            </div>

            {/* Highlight Your Profile */}
            <div>
              <h3 className="text-2xl font-bold text-black">
              Boost Your Career
              </h3>
              <p className="text-gray-700 mt-2">
              Add Your Certificate to LinkedIn or Your CV to Leave a Strong Impression
              </p>
            </div>

            {/* Icons and Call-to-Action */}
            <div className="flex items-center gap-4 mt-6 justify-center md:justify-start">
              <FaAward className="w-12 h-12 text-text-primary" />
              <p className="text-gray-600">
                Mentor-signed and officially verified by LevelUp.
              </p>
            </div>

            {/* <Link
              to="#"
              className="cta-button text-white py-[15px] px-[20px] md:px-[30px] rounded-xl hover:bg-blue-700 transition text-[18px] md:text-[24px] w-fit font-sans text-center m-auto md:mt-[20px] md:ml-0"
            >
            
              Get <span className="line-through">₹{(data?.total_amount) && data?.total_amount}</span> Only On ₹{(data?.final_amount) && data.final_amount}
            </Link> */}
            <RegisterButton/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkshopCertificate;
