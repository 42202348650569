import React, { useEffect, useState } from "react";
import Footer from '../components/Footer';
import CongratulationsSection from '../components/CongratulationsSection';
import ThreeColumnSection from '../components/ThreeColumnSection';
import Comparison from '../components/Comparison';
import YourPath from '../components/YourPath';
import FAQSection from '../components/FAQSection';
import AOS from "aos";
import "aos/dist/aos.css";

import { Link, useNavigate } from "react-router-dom";



const ThankYou = () => {
        const [apiIsLoading, setapiIsLoading] = useState(false)
        const navigate = useNavigate()
        useEffect(() => {
          AOS.init({
            duration: 1200,
            easing: "ease-in-out",
            once: true,
            mirror: false,
          });
        }, []);
    return (
        <>      
            <CongratulationsSection />
            <ThreeColumnSection />
            <Comparison />
            <YourPath />
            <FAQSection className="bg-white" />
            <Footer />
        </>
    );
};

export default ThankYou; 

