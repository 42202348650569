import axios from "axios"

const addVisitClick = async()=>{
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_PORT}clickCountWebsite`)
        
    }
    catch(err){
        console.log(err)
    }
    
}

const addRegistrationVisitClick = async()=>{
    try{
        const res = await axios.post(`${process.env.REACT_APP_API_PORT}clickCountRegister`)
    }
    catch(err){
        console.log(err)
    }
    
}

export {addVisitClick,addRegistrationVisitClick}