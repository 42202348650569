import React, { useState, useEffect, useRef } from 'react';

const CounterSection = () => {
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);
  const [startCounting, setStartCounting] = useState(false);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      if (entries[0].isIntersecting) {
        setStartCounting(true);
        observer.disconnect();
      }
    }, {
      threshold: 0.5
    });

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (observer && counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!startCounting) return;

    const interval1 = setInterval(() => {
      if (count1 < 95) setCount1(prev => prev + 1);
      else clearInterval(interval1);
    }, 10);

    const interval2 = setInterval(() => {
      if (count2 < 99) setCount2(prev => prev + 1);
      else clearInterval(interval2);
    }, 10);

    const interval3 = setInterval(() => {
      if (count3 < 50) setCount3(prev => prev + 1);
      else clearInterval(interval3);
    }, 10);

    const interval4 = setInterval(() => {
      if (count4 < 90) setCount4(prev => prev + 1);
      else clearInterval(interval4);
    }, 10);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
      clearInterval(interval4);
    };
  }, [startCounting, count1, count2, count3, count4]);

  return (
    <section className=' font-sans'>
        <div className="py-20" ref={counterRef} id="section-Rn11OoJRuk">
        <div className="container mx-auto text-center px-4 max-w-[67rem]" data-aos="fade-up">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
            {/* Counter 1 */}
            <div className="counter-item p-8 rounded-lg hover:shadow-2xl transition-shadow duration-300 border-[3px] border-blue-600">
              <h1 className="text-5xl font-bold text-blue-600 mb-4">{count1}%</h1>
              <p className="text-lg text-gray-600">of the content on this page was generated by AI.</p>
            </div>

            {/* Counter 2 */}
            <div className="counter-item p-8 rounded-lg hover:shadow-2xl transition-shadow duration-300 border-[3px] border-green-600">
                <h1 className="text-5xl font-bold text-green-600 mb-4">{count2}%</h1>
                <p className="text-lg text-gray-600">of our logo was created by AI.</p>
            </div>

            {/* Counter 3 */}
            <div className="counter-item p-8 rounded-lg hover:shadow-2xl transition-shadow duration-300 border-[3px] border-yellow-500">
                <h1 className="text-5xl font-bold text-yellow-500 mb-4">{count3}%</h1>
                <p className="text-lg text-gray-600">of the code behind our landing page was generated by AI.</p>
            </div>

            {/* Counter 4 */}
            <div className="counter-item p-8 rounded-lg hover:shadow-2xl transition-shadow duration-300 border-[3px] border-purple-600">
                <h1 className="text-5xl font-bold text-purple-600 mb-4">{count4}%</h1>
                <p className="text-lg text-gray-600">of the stock pictures are AI-generated.</p>
            </div>
            </div>
        </div>
        </div>
    </section>
  );
};

export default CounterSection;
