import { FaCheck } from "react-icons/fa6";

function BonusCard(props) {
  return (
    <div className="bonus-card flex flex-col items-center p-4 bg-white rounded-lg shadow-lg text-center max-w-xs mx-auto">
      {/* Icon at the top */}
      <FaCheck className="text-text-primary w-12 h-12 mb-4 bg-background-primary bg-opacity-10 p-2 rounded-lg" />

      {/* Content below */}
      <div className="text-[20px] font-medium text-gray-800">
        {props.title1} <span className="text-[24px] font-semibold"> {props.title2}</span>
      </div>
    </div>
  );
}

export default BonusCard;