import React from "react";

const ThreeColumnSection = () => {
  return (
    <section className="py-12 bg-gray-100 font-sans">
      <div className="container mx-auto px-4 max-w-[67rem]">
        <h2 className="text-[26px] md:text-[34px] text-center text-black mb-8 leading-tight" >
            The Tough Truth About <br />
         <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">
            Job Hunting 
            </span> 
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* First Box */}
          <div className="relative h-[380px] bg-gray-100 shadow-lg rounded-lg overflow-hidden">
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: "url('/images/challenges.png')",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
            <div className="relative z-10 flex flex-col justify-end h-full p-6">
              <h3 className="text-xl font-semibold text-white">Big Challenges</h3>
              <p className="text-sm text-white mt-2 leading-tight">
                Shocking but true - more than 80% of resumes get ignored. 1 out
                of 10 reaches manager.
              </p>
            </div>
          </div>

          {/* Second Box */}
          <div className="relative h-[380px] bg-gray-100 shadow-lg rounded-lg overflow-hidden">
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: "url('/images/grads.png')",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
            <div className="relative z-10 flex flex-col justify-end h-full p-6">
              <h3 className="text-xl font-semibold text-white">Tough Times for Grads</h3>
              <p className="text-sm text-white mt-2 leading-tight">
                It's harder than ever for new grads to find decent jobs.
              </p>
            </div>
          </div>

          {/* Third Box */}
          <div className="relative h-[380px] bg-gray-100 shadow-lg rounded-lg overflow-hidden">
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: "url('/images/takeover.png')",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
            <div className="relative z-10 flex flex-col justify-end h-full p-6">
              <h3 className="text-xl font-semibold text-white">The AI Takeover</h3>
              <p className="text-sm text-white mt-2 leading-tight">
                By 2025, AI might replace 85 million jobs. It's a race to stay ahead!
              </p>
            </div>
          </div>
        </div>
        {/* Career Boost Section */}
        <div className="flex flex-col items-center text-center space-y-12 mt-10">
            <div className="space-y-3 max-w-3xl">
                <h2 className="text-[26px] md:text-4xl font-bold text-black">
                    But don’t worry...
                </h2>
                <p className="text-lg text-gray-600">
                    In a world where opportunity knocks only once, are you prepared to answer?
                </p>
            </div>

            {/* Closing Section */}
            <div className="space-y-2">
                <h2 className="text-[26px] md:text-3xl font-bold text-black leading-tight">
                    Introducing the Dream Job Achiever Pack – your secret weapon in conquering today's ruthless job market.
                </h2>
            </div>
            <div>
            <a
                href="#"
                className="cta-button text-white py-[15px] px-[20px] md:px-[50px] rounded-xl hover:bg-blue-700 transition-all text-[18px] md:text-[24px] w-fit"
            >
                Enroll Now at <span className="line-through">₹ 6000</span> 299/$4
            </a>
            </div>
        </div>

      </div>
    </section>
  );
};

export default ThreeColumnSection;
