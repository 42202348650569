import React from "react";

const companies = [
  "https://storage.googleapis.com/msgsndr/TOke3l5JIz3qqeafaEbt/media/66163e7b93661bd0a2793be3.webp",
  "https://storage.googleapis.com/msgsndr/TOke3l5JIz3qqeafaEbt/media/66163e7b041ea74d4b547013.webp",
  "https://storage.googleapis.com/msgsndr/TOke3l5JIz3qqeafaEbt/media/66163e7b93661b472d793be2.webp",
  "https://storage.googleapis.com/msgsndr/TOke3l5JIz3qqeafaEbt/media/66163e7b9b2588afc4eee123.webp",
  "https://storage.googleapis.com/msgsndr/TOke3l5JIz3qqeafaEbt/media/66163e7b937a16169dcb01ae.webp",
];

const CompanySection = () => {
  return (
    <div className="bg-gray-50 py-10 overflow-hidden" data-aos="fade-up">
      <div className="container mx-auto text-center">
        <h2 className="text-[26px] md:text-[34px] text-black mb-8 max-w-[600px] m-auto leading-tight">
                <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">Trusted by</span> learning partners
              </h2>
        {/* Scrolling Wrapper */}
        <div className="relative flex items-center overflow-hidden">
          {/* Logos Container */}
          <div className="flex w-max animate-slide gap-10">
            {/* Render logos */}
            {companies.map((logo, index) => (
              <img
                key={index}
                src={logo}
                alt={`Company ${index + 1}`}
                className="h-16 w-auto object-contain"
              />
            ))}
            {/* Duplicate for seamless loop */}
            {companies.map((logo, index) => (
              <img
                key={`duplicate-${index}`}
                src={logo}
                alt={`Duplicate Company ${index + 1}`}
                className="h-16 w-auto object-contain"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanySection;
