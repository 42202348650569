import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import RegisterButton from "./RegisterButton";

function OfferSection() {
  // const [timeLeft, setTimeLeft] = useState({ minutes: 10, seconds: 0 });

  // useEffect(() => {
  //   const offerEndTime = new Date().getTime() + 10 * 60 * 1000; // Set offer end time for 30 minutes from now
  //   const countdown = setInterval(() => {
  //     const currentTime = new Date().getTime();
  //     const difference = offerEndTime - currentTime;

  //     if (difference <= 0) {
  //       clearInterval(countdown);
  //       setTimeLeft({ minutes: 0, seconds: 0 });
  //     } else {
  //       const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  //       const seconds = Math.floor((difference % (1000 * 60)) / 1000);
  //       setTimeLeft({ minutes, seconds });
  //     }
  //   }, 1000);

  //   return () => clearInterval(countdown); // Cleanup on component unmount
  // }, []);
  const [data, setData] = useState({});
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Helper function to format time with AM/PM
  const formatTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  };

  // Helper function to pad numbers with zero
  const padZero = (number) => (number < 10 ? "0" + number : number);

  // Function to calculate the remaining time
  const calculateTimeLeft = (targetDateTime) => {
    const currentTime = new Date();
    const timeRemaining = targetDateTime - currentTime;

    if (timeRemaining > 0) {
      return {
        hours: Math.floor(timeRemaining / (60 * 60 * 1000)),
        minutes: Math.floor((timeRemaining % (60 * 60 * 1000)) / (60 * 1000)),
        seconds: Math.floor((timeRemaining % (60 * 1000)) / 1000),
      };
    } else {
      return { hours: 0, minutes: 0, seconds: 0 }; // Offer ended
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_PORT}get_class_data`
        );
        if (res.data.status === true) {
          setData(res.data.data);
        }
      } catch (err) {
        console.error(err);
        if (err.response?.data?.status === false) {
          alert(err.response.data.message);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.date && data.start_time) {
      const targetDateTime = new Date(`${data.date}T${data.start_time}:00`);
console.log("targetDateTime",targetDateTime)
      const intervalId = setInterval(() => {
        setTimeLeft(calculateTimeLeft(targetDateTime));
      }, 1000);

      return () => clearInterval(intervalId); // Cleanup on unmount
    }
  }, [data]);

  return (
    <section className="py-16 bg-gray-200 font-sans" style={{ backgroundImage: 'url(/images/banner-4.png)', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}>
      <div className="container mx-auto px-6 lg:px-12 max-w-[67rem]">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          {/* Timer Section */}
          <div className="w-full lg:w-1/2 flex flex-col items-center bg-[#FFFFFF17] p-8 rounded-xl shadow-lg">
            <img
              src="https://cdn.prod.website-files.com/662a1d0916690044c1235ff4/662a1db9c1b0666f5aebc6ea_Mask%20group.svg"
              alt="Timer Icon"
              className="w-20 h-20 mb-4"
            />
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-2 text-center">Grap free Offer now</h2>
            <p className="text-lg text-white mb-4 text-center">Hurry up! Grab the deal before it expires.</p>
            {/* <div className="text-5xl font-extrabold text-white">
              {String(timeLeft.minutes).padStart(2, "0")}:
              {String(timeLeft.seconds).padStart(2, "0")}
            </div> */}
            <div className="text-5xl font-extrabold text-white">
            <span className="hours">{padZero(timeLeft.hours)}</span>:
            <span className="minutes">{padZero(timeLeft.minutes)}</span>:
            <span className="seconds">{padZero(timeLeft.seconds)}</span>
          </div>
          </div>

          {/* Offer Details Section */}
          <div className="w-full lg:w-1/2 text-center lg:text-left">
            <h2 className="text-3xl font-semibold text-white mb-4">Act Fast! </h2>
            <p className="text-lg text-[#CACACA] mb-8">
            Limited Time Offer to Save Big on Your Purchase!
            </p>
            {/* <Link
              to="#"
              className="cta-button text-white py-[15px] px-[20px] md:px-[50px] rounded-xl hover:bg-blue-700 transition  text-[18px] md:text-[24px] w-fit"
            >
              
              Get <span className="line-through">₹{(data.total_amount) && data.total_amount}</span> Value for Only ₹{(data.final_amount) && data.final_amount}
            </Link> */}
            <RegisterButton/>
          </div>
        </div>

      </div>
    </section>
  );
}

export default OfferSection;
