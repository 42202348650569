import React from 'react';
import { FaStar, FaReact, FaCog, FaUserAlt } from 'react-icons/fa'; // Example icons

const BonusSection = () => {
  return (
    <section className="py-16 pt-10 bg-gray-50 font-sans">
      <div className="container mx-auto px-6 md:px-12 max-w-[67rem]">
        <div className="text-center mb-12" data-aos="fade-up">
          <h2 className="text-[26px] md:text-[34px] text-center text-black mb-2 max-w-[600px] m-auto leading-tight">
          And enjoy <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent ">bonuses worth ₹5000 absolutely free</span>
          
          </h2>
        </div>

        {/* Bonus Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-12">
          {/* Card 1 */}
          <div className="overflow-hidden relative border border-gray-300 rounded-xl shadow-2xl p-8 transform transition-transform duration-500 hover:scale-105 hover:shadow-3xl relative" data-aos="fade-up">
            <div className="absolute top-4 left-4 bg-background-secondry text-white text-3xl font-bold w-16 h-16 rounded-full flex items-center justify-center -mt-6 -ml-6">
              <span className="font-[20px]">01</span>
            </div>
            <div className="mt-8 text-center">
              <h3 className="text-xl font-semibold text-black mb-6 transition-colors duration-300">
              Unlock GPT-4’s Full Potential with These Gold-Standard Prompts.
              </h3>
              <div className="">
                <FaStar className="absolute bottom-[-10px] right-[-50px] text-gray-300 opacity-60 w-40 h-40 z-[-1]" /> {/* Icon in background */}
              </div>
            </div>
          </div>

          {/* Card 2 */}
          <div className="overflow-hidden relative border border-gray-300 rounded-xl shadow-2xl p-8 transform transition-transform duration-500 hover:scale-105 hover:shadow-3xl relative" data-aos="fade-up">
            <div className="absolute top-4 left-4 bg-background-primary text-white text-3xl font-bold w-16 h-16 rounded-full flex items-center justify-center -mt-6 -ml-6">
              <span className="font-[20px]">02</span>
            </div>
            <div className="mt-8 text-center">
              <h3 className="text-xl font-semibold text-black mb-6 transition-colors duration-300">
              Simplified: The All-in-One AI Platform for Fast, Smart, and Powerful Results.
              </h3>
              <div className="">
                <FaReact className="absolute bottom-[-10px] right-[-50px] text-gray-300 opacity-60 w-40 h-40 z-[-1]" /> {/* Icon in background */}
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="overflow-hidden relative border border-gray-300 rounded-xl shadow-2xl p-8 transform transition-transform duration-500 hover:scale-105 hover:shadow-3xl relative" data-aos="fade-up">
            <div className="absolute top-4 left-4 bg-background-primary text-white text-3xl font-bold w-16 h-16 rounded-full flex items-center justify-center -mt-6 -ml-6">
              <span className="font-[20px]">03</span>
            </div>
            <div className="mt-8 text-center">
              <h3 className="text-xl font-semibold text-black mb-6 transition-colors duration-300">
              Zero to Hero: Coding Your First Website with No Tech Background.
              </h3>
              <div className="bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">
                <FaCog className="absolute bottom-[-10px] right-[-50px] text-gray-300 opacity-60 w-40 h-40 z-[-1] " /> {/* Icon in background */}
              </div>
            </div>
          </div>

          {/* Card 4 */}
          <div className="overflow-hidden relative border border-gray-300 rounded-xl shadow-2xl p-8 transform transition-transform duration-500 hover:scale-105 hover:shadow-3xl relative" data-aos="fade-up">
            <div className="absolute top-4 left-4 bg-background-secondry text-white text-3xl font-bold w-16 h-16 rounded-full flex items-center justify-center -mt-6 -ml-6">
              <span className="font-[20px]">04</span>
            </div>
            <div className="mt-8 text-center">
              <h3 className="text-xl font-semibold text-black mb-6 transition-colors duration-300">
              Create Human-Like AI Personas with Synthesia to Expand Your Team’s Capacity.
              </h3>
              <div className="">
                <FaUserAlt className="absolute bottom-[-10px] right-[-50px] text-gray-300 opacity-60 w-40 h-40 z-[-1]" /> {/* Icon in background */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BonusSection;
