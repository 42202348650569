import React from 'react';
import { HiOutlinePencilAlt, HiOutlineChartBar, HiOutlineSpeakerphone, HiOutlineSearch, HiOutlineChip, HiOutlineSupport } from 'react-icons/hi';

const slidesData = [
  {
    Icon: HiOutlinePencilAlt,
    title: "Master Content Creation",
    description: "Automate and personalise content across platforms to boost engagement, save time, and enhance audience connection.",
  },
  {
    Icon: HiOutlineChartBar,
    title: "Boost Your Data Skills",
    description: "Use AI to simplify complex data analysis, providing deep insights without needing advanced statistical skills.",
  },
  {
    Icon: HiOutlineSpeakerphone,
    title: "Supercharge Your Marketing Career",
    description: "Boost your marketing career with AI tools, powerful strategies, and impactful results that drive growth.",
  },
  {
    Icon: HiOutlineSearch,
    title: "Digital Marketers, Drive More Results",
    description: "Stay ahead in a fast-evolving industry by mastering AI-powered market analysis, content creation, and strategy.",
  },
  {
    Icon: HiOutlineSupport,
    title: "Transform Your Support Team",
    description: "Use AI to offer personalised, context-driven support, boosting customer satisfaction, loyalty, and overall engagement.",
  },
  {
    Icon: HiOutlineChip,
    title: "Explore AI – Get Started Today",
    description: "Become essential in your field by using AI tools that boost productivity, help make better decisions, and improve performance.",
  }
];

const WorkshopGrid = () => {
  return (
    <section className="py-16 pt-10 font-sans" style={{ backgroundImage: 'url(/images/banner-4.png)', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}>
      <div className="container mx-auto max-w-5xl px-4">
        <div className="text-center mb-12" data-aos="fade-up">
          <h2 className="text-white text-[26px] md:text-[34px] tracking-wider leading-tight">
            This Workshop is <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">Uniquely Tailored</span> For
          </h2>
        </div>

        {/* Grid Layout */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8" data-aos="fade-up">
          {slidesData.map((slide, index) => (
            <div
              key={index}
              className="bg-[#FFFFFF17] rounded-xl p-8 shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-105 flex flex-col items-center text-center relative"
            >
              <div className='bg-[#FFFFFF17] rounded-xl p-2 mb-4'><slide.Icon className="text-text-primary text-4xl  transition-transform duration-300 hover:scale-110" /></div>
              <h3 className="text-white text-[22px] font-bold mb-4 transition-colors duration-300 leading-none">
                {slide.title}
              </h3>
              <p className="text-[#CACACA] text-sm">{slide.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkshopGrid;
