import React from 'react';

const AIToolsSection = () => {
  return (
    <>
    <section className="section py-12 relative" data-aos="fade-up">
      <div className="container mx-auto px-4 max-w-[67rem]">
        <div className="container-medium w-container mx-auto">
          <div className="tools">
            <div className="heading-h2-wrapper mb-12 text-center">
              <h2 className="text-[26px] md:text-[34px] text-center text-black mb-8 leading-tight">
                <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">Unlock 25+</span> AI Tools That Will Supercharge Your Efficiency
            </h2>
            </div>

            <div className="tools-wrapper grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
                {/* Tool 12 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic12.webp"
                    loading="lazy"
                    alt="Tool 12"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 4 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic4.webp"
                    loading="lazy"
                    alt="Tool 4"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 7 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic7.webp"
                    loading="lazy"
                    alt="Tool 7"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 16 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic16.webp"
                    loading="lazy"
                    alt="Tool 16"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 5 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic5.webp"
                    loading="lazy"
                    alt="Tool 5"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 9 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic9.webp"
                    loading="lazy"
                    alt="Tool 9"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 3 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic3.webp"
                    loading="lazy"
                    alt="Tool 3"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 13 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic13.webp"
                    loading="lazy"
                    alt="Tool 13"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 18 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic18.webp"
                    loading="lazy"
                    alt="Tool 18"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 10 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic10.webp"
                    loading="lazy"
                    alt="Tool 10"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 6 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic6.webp"
                    loading="lazy"
                    alt="Tool 6"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 2 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic2.webp"
                    loading="lazy"
                    alt="Tool 2"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 15 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic15.webp"
                    loading="lazy"
                    alt="Tool 15"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 17 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic17.webp"
                    loading="lazy"
                    alt="Tool 17"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 1 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic1.webp"
                    loading="lazy"
                    alt="Tool 1"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 8 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic8.webp"
                    loading="lazy"
                    alt="Tool 8"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 11 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic11.webp"
                    loading="lazy"
                    alt="Tool 11"
                    className="tool-logo"
                    />
                </div>

                {/* Tool 14 */}
                <div className="tool border border-gray-300 bg-white rounded-xl flex justify-center items-center px-6 py-4 shadow-lg">
                    <img
                    src="/images/ic14.webp"
                    loading="lazy"
                    alt="Tool 14"
                    className="tool-logo"
                    />
                </div>
                </div>


          </div>
        </div>
      </div>
      {/* Add div-block-1595 at the bottom */}
      <div className="div-block-1595 absolute bottom-0 left-0 w-full">
        <div className="bg-gradient-to-b from-transparent to-white w-full h-[180px]"></div>
      </div>
    </section>
    <p className='text-center font-semibold font-sans pb-[60px] text-black'>And Many More!</p>
    </>
  );
};

export default AIToolsSection;
