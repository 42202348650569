import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { addRegistrationVisitClick } from '../utils/AddClick'

function RegisterButton() {
    const navigate = useNavigate()
    const [data,setData]= useState()
    const getData = async() => {
        try{
          const res =await axios.get(`${process.env.REACT_APP_API_PORT}get_class_data`)
          console.log(res)
          if(res.data.status===true){
            console.log(res.data)
            setData(res.data.data)
          }
        }
        catch(err){
          console.log(err)
          if(err.response.data.status===false){
            alert(err.response.data.message)
          }
        }
      }
    
      useEffect(()=>{
        getData()
        
      },[])
  return (
    <>
               <button
                //   to=""
                  onClick={()=>{
                    navigate("/masterclass-registration")
                    addRegistrationVisitClick()
                  }}
                  className="cta-button text-white py-[15px] px-[20px] md:px-[50px] rounded-xl hover:bg-blue-700 transition-all text-[18px] md:text-[24px] text-center w-fit"
                >
                  {/* Join Now for ₹99 <span className="line-through">₹1,999</span> */}
                  Get <span className="line-through">₹{(data?.total_amount) && data?.total_amount}</span> Value for {(data?.final_amount) && <>
                    {(data.amount_type==="Free") ? "FREE" : `Only ₹${data.final_amount}`}
                  </>}
                </button>
    </>
  )
}

export default RegisterButton