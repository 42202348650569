import React, { useEffect, useState } from "react";
import { FaCalendarAlt, FaClock } from "react-icons/fa";
import BonusCard from "./BonusCard";
import AOS from "aos"; // Importing AOS
import "aos/dist/aos.css"; // Importing AOS styles
import { Link } from 'react-router-dom';
import axios from "axios";
import moment from "moment/moment";
import RegisterButton from "./RegisterButton";

const HeroSection = () => {
  const [data,setData] = useState([])
  useEffect(() => {
    // Initialize AOS with custom settings
    AOS.init({
      duration: 1200,  // Duration of the animation
      easing: "ease-in-out",  // Easing function for smoother transitions
      once: true,  // Animation happens only once
      mirror: true, // Allow animation on scroll up
    });
  }, []);

  const formatTime = (time) => {
    const [hours, minutes] = time?.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
  };

  const getData = async() => {
    try{
      const res =await axios.get(`${process.env.REACT_APP_API_PORT}get_class_data`)
      console.log(res)
      if(res.data.status===true){
        console.log(res.data)
        setData(res.data.data)
      }
    }
    catch(err){
      console.log(err)
      if(err.response.data.status===false){
        alert(err.response.data.message)
      }
    }
  }

  useEffect(()=>{
    getData()
  },[])

  return (
    <div className="relative pb-6">
      {/* Background Image with reduced opacity */}
      <div
        className="absolute inset-0 bg-cover bg-center bg-no-repeat bg-opacity-30"
        style={{ backgroundImage: 'url(/images/ai-back.jpg)', opacity: '.13' }}
      ></div>

      {/* Content */}
      <div className="relative z-10 container max-w-[67rem] mx-auto py-12 px-4 font-sans">
        <div className="items-center flex text-center justify-center">
          <Link to="/">
            <img
              src="/images/NFA-Logo.png"
              alt="Logo"
              className="bg-gradient w-[220px] p-3 py-2 rounded mb-12 mt-5"
              data-aos="fade-down" // AOS fade down for logo
            />
          </Link>
        </div>
        <div className="flex flex-col gap-12">
          {/* Hero Section */}
          <div className="flex flex-col lg:flex-row lg:justify-between items-center gap-8" >
            {/* Right Image for Desktop */}
            <div className="w-full lg:w-1/2" data-aos="fade-right">
              <div className="mb-6 text-center md:text-left">
                <h1 className="text-[28px] md:text-[55px] leading-none font-bold bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">
                  Unlock 10X Productivity 
                </h1>
                <h2 className="text-[26px] md:text-[30px] leading-none font-bold text-black mt-2">
                  and 2X Salary with 25 AI Tools & Automation!
                </h2>
              </div>

              <p className="text-[16px] md:text-[24px] font-medium text-black mb-8 text-center md:text-left" >
              Cut down 100 hours weekly and 2X your effectiveness with expert-level generative AI skills.

              </p>

              {/* Date and Duration */}
              <div className="flex gap-4 mb-6 justify-center md:justify-start">
                <div className="flex gap-x-3 gap-y-3 bg-[#f3f8fe] rounded-md flex flex-row justify-start items-center p-2.5 md:pr-[50px]" >
                  <FaCalendarAlt className="w-8 h-8 text-text-primary" />
                  <span className="text-[14px] md:text-[16px] font-medium">
                    {/* 17th Nov, 2024 | 11
                    <br /> AM onwards */}
                    {data && moment(data.date).format('LL')} | {data.start_time && formatTime(data.start_time)}
                  </span>
                </div>
                <div className="flex gap-x-3 gap-y-3 bg-[#f3f8fe] rounded-md flex flex-row justify-start items-center p-2.5  md:pr-[50px]" >
                  <FaClock className="w-8 h-8 text-text-primary" />
                  <span className="text-[14px] md:text-[16px] font-medium">{(data.hour && data.hour)} Hours</span>
                </div>
              </div>

              {/* CTA Button and Seats Info */}
              <div className="flex flex-col mb-0 md:mb-8 items-center md:items-start" >
                {/* <a
                  href="#"
                  className="cta-button text-white py-[15px] px-[20px] md:px-[50px] rounded-xl hover:bg-blue-700 transition-all text-[18px] md:text-[24px] text-center w-fit"
                >
                 
                  Get <span className="line-through">₹{(data?.total_amount) && data?.total_amount}</span> Value for Only ₹{(data?.final_amount) && data.final_amount}
                </a> */}
                <RegisterButton/>
                <p className="text-[20px] font-medium mt-2 text-black">
                Limited Spots! <span className="font-bold">{(data.seat_left) && data.seat_left}</span> left! ⏳
                </p>
              </div>
            </div>

            {/* Left Content */}
            <div className="w-full lg:w-1/2 lg:mr-8" data-aos="fade-left">
              <a href="#" className="inline-block w-full">
                <img
                  src="/images/hero-ben.jpg"
                  alt="Hero Image"
                  className="w-full rounded-[30px] border border-gray-300"
                />
              </a>
            </div>
          </div>

          {/* Bonus Section */}
          <h2 className="text-[26px] md:text-[34px] text-center text-black mb-0 md:mb-8 leading-tight" data-aos="fade-up">
          Hurry! Register Before Midnight and 
            <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">
             &nbsp;Grab ₹5,000 in Bonuses
            </span> 
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-12" data-aos="fade-up">
            <BonusCard title1="Step Up Your Game" title2="Master AI Prompting Today
" />
            <BonusCard title1="Simplify Data Analysis" title2="No Coding, Just Results" />
            <BonusCard title1="Simplify Your Day" title2="AI Intern Handles the Repetitive Tasks" />
            <BonusCard title1="Stand Out" title2="How to Become Irreplaceable at Your Job" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
