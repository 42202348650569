import React from 'react';
import MasterclassRegistration from '../components/MasterclassRegistration';
import Footer from '../components/Footer';


const MasterclassRegistrationPage = () => {
    return (
        <>      
            <MasterclassRegistration />
            <Footer />
        </>
    )
}

export default MasterclassRegistrationPage; 