import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import { FaBuilding, FaPhoneAlt, FaEnvelope } from "react-icons/fa"; // Import Check Icon
import AOS from "aos";
import "aos/dist/aos.css";
import ReCAPTCHA from "react-google-recaptcha"; // Add reCAPTCHA import
import Footer from "../components/Footer";

const ContactUs = () => {
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState(""); // State for message
  const [captchaVerified, setCaptchaVerified] = useState(false); // State for CAPTCHA verification

  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(true); // When CAPTCHA is verified
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!captchaVerified) {
      alert("Please verify that you are not a robot.");
      return;
    }
    // Handle form submission logic here
    alert("Form submitted successfully!");
  };

  return (
    <>
    <div className="items-center flex text-center justify-center bg-gray-800">
            <Link to="/">
            <img
            src="/images/NFA-Logo.png"
            alt="Logo"
            className="bg-gradient w-[220px] p-3 py-2 rounded mb-12 mt-12"
            />
            </Link>
        </div>
    <section className="py-12 font-sans" data-aos="fade-up">
      <div className="container mx-auto px-4 max-w-[67rem]">

        {/* Main Section */}
        <div className="bg-white ">
          <h2 className="text-[26px] md:text-[34px] text-center text-black mb-5 leading-tight" >
            <span className="font-bold text-[35px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">
              Contact Us
            </span>
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Left Section - Contact Information */}
            <div
              className="p-5 md:p-6 border border-gray-300 rounded-xl bg-white "
            >
              <h3 className="text-[20px] md:text-[24px] font-bold text-black mb-6">
                You May Contact Us Using The Information Below:
              </h3>
              <ul className="space-y-6">
                {/* Contact Item */}
                <li className="flex items-start space-x-4 hover:bg-gray-50 rounded-md">
                  <span className="flex items-center justify-center h-[40px] w-[40px] md:h-[50px] md:w-[50px] min-w-[40px] md:min-w-[50px] inline bg-background-primary bg-opacity-10 rounded-full">
                    <FaBuilding className="text-text-secondry text-[20px] md:text-[25px]" />
                  </span>
                  <div className="text-gray-700 text-lg font-medium">
                    <strong className="text-black">Merchant Legal Entity Name:</strong>
                    <span className="block">FREEDOM WITH AI TECHNOLOGIES PRIVATE LIMITED</span>
                  </div>
                </li>
                <li className="flex items-start space-x-4 hover:bg-gray-50 rounded-md">
                  <span className="flex items-center justify-center h-[40px] w-[40px] md:h-[50px] md:w-[50px] inline bg-background-primary bg-opacity-10 rounded-full">
                    <FaPhoneAlt className="text-text-secondry text-[20px]" />
                  </span>
                  <div className="text-gray-700 text-lg font-medium">
                    <strong className="text-black">Telephone No:</strong>
                    <Link to='tel:+917842563666'> <span className="block">+91 78425 63666</span> </Link>
                  </div>
                </li>
                <li className="flex items-start space-x-4 hover:bg-gray-50 rounded-md">
                  <span className="flex items-center justify-center h-[40px] w-[40px] md:h-[50px] md:w-[50px] inline bg-background-primary bg-opacity-10 rounded-full">
                    <FaEnvelope className="text-text-secondry text-[20px]" />
                  </span>
                  <div className="text-gray-700 text-lg font-medium">
                    <strong className="text-black">E-Mail ID:</strong>
                    <Link to='mailto:info@nextfutureai.com'> <span className="block">info@nextfutureai.com</span> </Link>
                  </div>
                </li>
              </ul>
            </div>

            {/* Right Section - Form */}
            <form
              className="border border-gray-300 rounded-lg p-5 md:p-6"
              onSubmit={handleSubmit} // Handle form submit
            >
              <div className="mb-6">
                <label
                  htmlFor="full_name"
                  className="block text-gray-700 text-sm font-semibold mb-2"
                >
                  Full Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="full_name"
                  name="full_name"
                  placeholder="Enter your full name"
                  className="w-full px-4 py-3 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 shadow-sm hover:shadow-md"
                  required
                />
              </div>

              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block text-gray-700 text-sm font-semibold mb-2"
                >
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  className="w-full px-4 py-3 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 shadow-sm hover:shadow-md"
                  required
                />
              </div>

              {/* Message Field */}
              <div className="mb-6">
                <label
                  htmlFor="message"
                  className="block text-gray-700 text-sm font-semibold mb-2"
                >
                  Message <span className="text-red-500">*</span>
                </label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Enter your message"
                  className="w-full px-4 py-3 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 shadow-sm hover:shadow-md max-h-[50px]"
                  rows="4"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)} // Update message state
                />
              </div>

              {/* CAPTCHA */}
              <div className="mb-6">
                <ReCAPTCHA
                  sitekey="your-recaptcha-site-key" // Replace with your actual reCAPTCHA site key
                  onChange={handleCaptchaChange}
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="cta-button w-full bg-gradient-to-r from-green-400 to-blue-500 text-white font-semibold py-3 rounded-lg shadow-md hover:shadow-lg transition duration-300"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <Footer />
    </>
  );
};

export default ContactUs;
