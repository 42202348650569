// import axios from 'axios';
// import React, { useState, useEffect } from 'react';
// import { Link } from "react-router-dom";

// const CountdownTimer = () => {
//   const [data,setData] = useState([])
 
//   // State for the countdown
//   const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });

//   // Helper function to pad numbers with zero
//   const padZero = (number) => (number < 10 ? '0' + number : number);

//   // Function to calculate the remaining time until midnight
//   const calculateInitialTime = () => {
//     const currentTime = new Date();
//     const targetTime = new Date(currentTime.getTime() + 24 * 60 * 60 * 1000);
//     targetTime.setHours(0, 0, 0, 0); // Reset at midnight

//     const timeRemaining = targetTime - currentTime;
//     return {
//       hours: Math.floor(timeRemaining / (60 * 60 * 1000)),
//       minutes: Math.floor((timeRemaining % (60 * 60 * 1000)) / (60 * 1000)),
//       seconds: Math.floor((timeRemaining % (60 * 1000)) / 1000),
//     };
//   };

//   // Start the countdown when the component mounts
//   useEffect(() => {
//     const updateTime = () => {
//       let { hours, minutes, seconds } = calculateInitialTime();
//       setTimeLeft({ hours, minutes, seconds });

//       const countdownInterval = setInterval(() => {
//         if (seconds === 0) {
//           if (minutes === 0 && hours === 0) {
//             const nextTime = calculateInitialTime();
//             setTimeLeft(nextTime);
//           } else if (minutes === 0) {
//             setTimeLeft({ hours: hours - 1, minutes: 59, seconds: 59 });
//           } else {
//             setTimeLeft({ hours, minutes: minutes - 1, seconds: 59 });
//           }
//         } else {
//           setTimeLeft((prev) => ({ ...prev, seconds: prev.seconds - 1 }));
//         }
//       }, 1000);

//       return () => clearInterval(countdownInterval); // Clean up the interval on unmount
//     };

//     updateTime();
//   }, []);

//   // Handle "REGISTER NOW!" button click
//   const handleRegisterClick = () => {
//     if (typeof window !== 'undefined' && window.webengage) {
//       window.webengage.track('Register Now Clicked', {
//         Clicked_at: new Date().toISOString(),
//       });
//       console.log('Register Now Clicked event sent to WebEngage');
//     }
//   };

  

//   const getData = async() => {
//     try{
//       const res =await axios.get(`${process.env.REACT_APP_API_PORT}get_class_data`)
//       console.log(res)
//       if(res.data.status===true){
//         console.log(res.data)
//         setData(res.data.data)
//       }
//     }
//     catch(err){
//       console.log(err)
//       if(err.response.data.status===false){
//         alert(err.response.data.message)
//       }
//     }
//   }

//   useEffect(()=>{
//     getData()
//   },[])

//   return (
//     <div className="sticky-container z-10 fixed bottom-0 left-0 w-full bg-[#000d20] py-2 px-4 flex justify-between items-center border-t border-gray-600 shadow-md">
//       <div className="flex flex-col sm:flex-row items-start md:items-center space-y-0 sm:space-y-0 sm:space-x-8 text-white">
//         <div className="price-strike">
//           <span className="strikethrough-price text-gray-400 line-through">₹{(data.total_amount) && data.total_amount}</span>
//           <span className="free-text text-lg font-bold"> ₹{(data.final_amount) && data.final_amount}</span>
//         </div>
//         <div className="offer-prompt sm:text-left">
//           <div>Offer ends in</div>
//           <div className="timer text-xl font-semibold">
//             <span className="hours">{padZero(timeLeft.hours)}</span>:
//             <span className="minutes">{padZero(timeLeft.minutes)}</span>:
//             <span className="seconds">{padZero(timeLeft.seconds)}</span>
//           </div>
//         </div>
//       </div>

//       {/* REGISTER NOW button */}
//       <div className='relative'>
//         <div class="persisitant-seat-left">
//           <div>
//             <span class="seat">{(data.seat_left) && data.seat_left}</span> seats left
//           </div>
//         </div>
//         <Link
//         to="/masterclass-registration"
//         className="cta-button text-white py-[10px] px-[20px] md:px-[40px] rounded-md hover:bg-blue-700 transition text-[16px] md:text-[18px] w-fit transition-all"
//         >
//         Register Now
//       </Link>
//       </div>
//     </div>
//   );
// };

// export default CountdownTimer;


import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addRegistrationVisitClick } from "../utils/AddClick";

const CountdownTimer = () => {
  const navigate = useNavigate()
  const [data, setData] = useState({});
  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Helper function to format time with AM/PM
  const formatTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
  };

  // Helper function to pad numbers with zero
  const padZero = (number) => (number < 10 ? "0" + number : number);

  // Function to calculate the remaining time
  const calculateTimeLeft = (targetDateTime) => {
    const currentTime = new Date();
    const timeRemaining = targetDateTime - currentTime;

    if (timeRemaining > 0) {
      return {
        hours: Math.floor(timeRemaining / (60 * 60 * 1000)),
        minutes: Math.floor((timeRemaining % (60 * 60 * 1000)) / (60 * 1000)),
        seconds: Math.floor((timeRemaining % (60 * 1000)) / 1000),
      };
    } else {
      return { hours: 0, minutes: 0, seconds: 0 }; // Offer ended
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_PORT}get_class_data`
        );
        if (res.data.status === true) {
          setData(res.data.data);
        }
      } catch (err) {
        console.error(err);
        if (err.response?.data?.status === false) {
          alert(err.response.data.message);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.date && data.start_time) {
      const targetDateTime = new Date(`${data.date}T${data.start_time}:00`);
console.log("targetDateTime",targetDateTime)
      const intervalId = setInterval(() => {
        setTimeLeft(calculateTimeLeft(targetDateTime));
      }, 1000);

      return () => clearInterval(intervalId); // Cleanup on unmount
    }
  }, [data]);

  return (
    <div className="sticky-container z-10 fixed bottom-0 left-0 w-full bg-[#000d20] py-2 px-4 flex justify-between items-center border-t border-gray-600 shadow-md">
      <div className="flex flex-col sm:flex-row items-start md:items-center space-y-0 sm:space-y-0 sm:space-x-8 text-white">
        <div className="price-strike">
          <span className="strikethrough-price text-gray-400 line-through">
            ₹{(data?.total_amount) || ""}
          </span>
          <span className="free-text text-lg font-bold"> {(data?.amount_type==="Free") ? "FREE" : `₹${data?.final_amount}` || ""} </span>
        </div>
        <div className="offer-prompt sm:text-left">
          <div>Offer ends in</div>
          <div className="timer text-xl font-semibold">
            <span className="hours">{padZero(timeLeft.hours)}</span>:
            <span className="minutes">{padZero(timeLeft.minutes)}</span>:
            <span className="seconds">{padZero(timeLeft.seconds)}</span>
          </div>
        </div>
      </div>

      {/* REGISTER NOW button */}
      <div className="relative">
        <div className="persisitant-seat-left">
          <div>
            <span className="seat">{data.seat_left}</span> seats left
          </div>
        </div>
        <button
          // to="/masterclass-registration"
          onClick={()=>{
            navigate("/masterclass-registration")
            addRegistrationVisitClick()
          }}
          className="cta-button text-white py-[10px] px-[20px] md:px-[40px] rounded-md hover:bg-blue-700 transition text-[16px] md:text-[18px] w-fit transition-all"
        >
          Register Now
        </button>
      </div>
    </div>
  );
};

export default CountdownTimer;
