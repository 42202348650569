import { useState } from 'react';
import { FaLightbulb } from 'react-icons/fa'; // Importing a single icon

const LearnSection = () => {
  const [openIndex, setOpenIndex] = useState(null); // State to track open dropdown

  // Array with learning points and the same icon for all items
  const learningPoints = [
    {
      title: "Unlock Creativity – The 4-Step Magic Formula",
      description:
        "Master ChatGPT with the magic prompt formula for the most accurate and trustworthy information.",
      icon: <FaLightbulb className="text-text-primary" /> // Single Icon for all items
    },
    {
      title: "Master Market Analysis – Stay Ahead",
      description:
        "Learn to gain accurate insights by analyzing market trends with AI-powered data analysis, no coding required.",
      icon: <FaLightbulb className="text-text-primary" /> // Single Icon for all items
    },
    {
      title: "Create Like a Pro – Redefine Content",
      description:
        "Use AI-driven content generation tools to automate social media posts, customize content, and spot trending topics.",
      icon: <FaLightbulb className="text-text-primary" /> // Single Icon for all items
    },
    {
      title: "Data Analysis Made Easy – Take Action",
      description:
        "Utilize AI-driven data analysis tools to extract valuable insights from big data for smarter decisions.",
      icon: <FaLightbulb className="text-text-primary" /> // Single Icon for all items
    },
    {
      title: "Impress with Marketing Presentations",
      description:
        "Use AI-driven analytics tools for real-time insights, optimized spreadsheets, and automated reports.",
      icon: <FaLightbulb className="text-text-primary" /> // Single Icon for all items
    },
    {
      title: "Stay Future-Ready – Adapt to AI",
      description:
        "Discover ChatGPT API integration for customer service, social media management, and its new vision capabilities.",
      icon: <FaLightbulb className="text-text-primary" /> // Single Icon for all items
    },
    {
      title: "Unlock Career Opportunities – Leap Forward",
      description:
        "Leverage AI for job matching, resume enhancement, and personalized career guidance to boost your marketing career.",
      icon: <FaLightbulb className="text-text-primary" /> // Single Icon for all items
    },
    {
      title: "Stand Out – Innovate Marketing & Branding",
      description:
        "Boost your personal brand’s visibility and credibility by automating content creation with generative AI tools.",
      icon: <FaLightbulb className="text-text-primary" /> // Single Icon for all items
    },
    // {
    //   title: "AI Tools for Success",
    //   description:
    //     "Utilize AI-powered tools for text summarization, sentiment analysis, and localizing content for global audiences.",
    //   icon: <FaLightbulb className="text-text-primary" /> // Single Icon for all items
    // },
  ];

  const handleToggle = (index) => {
    // Toggle dropdown visibility for clicked item only
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="bg-gray-200 py-10 pb-16 font-sans">
      <div className="container mx-auto px-4 max-w-[67rem]">
        <div className="text-center mb-10" data-aos="fade-up">
          <h2 className="text-[26px] md:text-[34px] text-center text-black mb-8 leading-tight">
          Key Takeaways from This <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">Session</span>
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6" data-aos="fade-up">
          {learningPoints.map((point, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md p-4 cursor-pointer transition-all duration-300 ease-in-out max-h-max"
              onClick={() => handleToggle(index)}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  {/* Render the single icon for all items */}
                  {point.icon}
                  <h3 className="text-lg font-semibold text-black ml-3">
                    {point.title}
                  </h3>
                </div>
                <svg
                  className={`w-6 h-6 text-black transform transition-transform ${
                    openIndex === index ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              <div
                className={`transition-all duration-500 ease-in-out overflow-hidden ${
                  openIndex === index ? "max-h-[200px] mt-4" : "max-h-0"
                }`}
              >
                <p className="text-gray-700">{point.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LearnSection;
