import React from "react";
import { Link } from "react-router-dom";

const YourPath = () => {
  return (
    <section className="py-16 bg-gray-100 font-sans">
      <div className="container mx-auto px-6 lg:px-12 max-w-[70rem] text-center">
        {/* Heading */}
        <h2 className="text-[26px] md:text-[34px] text-center text-black mb-5 leading-tight">
          <span className="font-bold text-[35px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">
            Your Path 
          </span>
          &nbsp;to the Top
        </h2>

        {/* Subheading */}
        <p className="text-lg md:text-xl text-gray-600 mb-12">
          Unlock success with strategies tailored for go-getters. Here's why
          you’ll stand out:
        </p>

        {/* List */}
        <div className="mb-12">
          <ol className="list-decimal font-bold list-inside text-left text-black text-base md:text-lg space-y-4 mx-auto max-w-2xl">
            <li>
              <strong className="text-black">Designed for Winners:</strong> <br /> <span className="text-[#4b5563] font-[400]">More than tools, it's your
              blueprint to outshine the competition.</span>
            </li>
            <li>
              <strong className="text-black">Real-World Strategies:</strong> <br /> <span className="text-[#4b5563] font-[400]">Learn from industry
              leaders' tactics, proven to boost job success by 70%.</span>
            </li>
            <li>
              <strong className="text-black">Personalized Approach:</strong> <br /> <span className="text-[#4b5563] font-[400]">Tailor the bundle to fit
              your strengths, potentially doubling interview callbacks.</span>
            </li>
            <li>
              <strong className="text-black">Continuous Learning:</strong> <br /> <span className="text-[#4b5563] font-[400]">Access a rich resource
              library for ongoing skill enhancement, increasing employability by
              40%.</span>
            </li>
            <li>
              <strong className="text-black">Networking Opportunities:</strong> <br /> <span className="text-[#4b5563] font-[400]">Join our community for
              connections that unlock 80% of hidden job opportunities.</span>
            </li>
          </ol>
        </div>

        {/* Call to Action */}
        <div className="mb-8">
          <p className="text-lg md:text-xl font-medium text-gray-700">
            <span className="italic">
              Your future-self will thank you if you take action NOW!
            </span>
          </p>
        </div>

        {/* Button */}
        <Link
                to="#"
                className="cta-button text-white py-[15px] px-[20px] md:px-[50px] rounded-xl hover:bg-blue-700 transition-all text-[18px] md:text-[24px] w-fit"
            >
                Enroll Now at <span className="line-through">₹ 6000</span> 299/$4
        </Link>
      </div>
    </section>
  );
};

export default YourPath;
