import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa'; // For social media icons
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="bg-gray-800 text-white py-10">
      <div className="ontainer mx-auto px-6 lg:px-12 max-w-[67rem]">
        
        {/* Second Row: Horizontal Menu */}
        <div className="mt-8 text-center">
          <ul className="block md:flex justify-center space-x-0 md:space-x-10">
            <li><Link to="/privacy-policy" className="text-gray-300 hover:text-text-secondry transition-colors">Privacy Policy</Link></li>
            <li><Link to="/terms-service" className="text-gray-300 hover:text-text-secondry transition-colors">Terms of Service</Link></li>
            <li><Link to="/contact-us" className="text-gray-300 hover:text-text-secondry transition-colors">Contact Us</Link></li>
            <li><Link to="/cancellation-refund-policy" className="text-gray-300 hover:text-text-secondry transition-colors">Cancellation & Refund Policy</Link></li>
          </ul>
        </div>

        {/* Third Row: Social Icons */}
        <div className="mt-8 text-center">
          <ul className="flex justify-center space-x-8">
            <li>
              <Link to="https://facebook.com" className="text-blue-600 hover:text-blue-800 transition-all">
                <FaFacebook size={28} />
              </Link>
            </li>
            <li>
              <Link to="https://twitter.com" className="text-blue-400 hover:text-blue-600 transition-all">
                <FaTwitter size={28} />
              </Link>
            </li>
            <li>
              <Link to="https://instagram.com" className="text-pink-500 hover:text-pink-700 transition-all">
                <FaInstagram size={28} />
              </Link>
            </li>
            <li>
              <Link to="https://linkedin.com" className="text-blue-700 hover:text-blue-900 transition-all">
                <FaLinkedin size={28} />
              </Link>
            </li>
          </ul>
        </div>

        {/* Footer Bottom */}
        <div className="mt-12 text-center text-sm text-gray-400">
          <p>&copy; {new Date().getFullYear()} NextFutureAi. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
